import React, { useEffect } from "react";
import { GetServerSidePropsContext } from "next";
import { useRouter } from "next/router";
import {  useDispatch, useSelector } from "react-redux";
import Head from "next/head";

import { ShareInfo } from "@/assets/apitype/share";
import { RootState } from "@/store";
import { userSaveSlice } from "@/store/userSave";
import CheckAuth from "@/components/common/CheckAuth";
import { checkPermission } from "@/util/permissonUtil";

//Page Props
import { PageProps } from "@/assets/pageprops/index";
import SelectShareDialog from "@/components/custom/ConfirmShareDialog";
import * as storageUtil from "@/util/storageUtil";
import * as viewUtil from "@/util/viewUtil";

//URL パラメータの受け取り
export async function getServerSideProps(context:GetServerSidePropsContext) {
  return {
    props: {
      code: viewUtil.getQueryStringOne(context.query.code),
      share: viewUtil.getQueryStringOne(context.query.share),
    },
  }
}

//Main
const Index = (props: PageProps): JSX.Element => {
  const router = useRouter();

  //Store, Dispatcher
  const dispatch = useDispatch();
  const userStore = useSelector((state: RootState) => state.user);
  const userSaveStore = useSelector((state: RootState) => state.userSave);

  const [shareID, setShareID] = React.useState('');

  const [openShareDialog, setOpenShareDialog] = React.useState(false);

  //DEF componentDidMount
  const componentDidMount = ()=>{
      dispatch(userSaveSlice.actions.initOnDidMount());

    //URL共有があれば判断を促す
    const share = storageUtil.getLimitedItem('share=' + props.share);
    if (share) {
      setShareID(share);
      setOpenShareDialog(true);
    }
    else if (userSaveStore.url) {
      router.push(userSaveStore.url);
    }
    else {
      router.push('/mainpage');
    }

  };

  //DEF componentWillUnmount
  const componentWillUnmount = ()=>{
    dispatch(userSaveSlice.actions.resetOnWillUnmount());
  }

  useEffect(() => {
    //DO componentWillUnmount
    componentDidMount.bind(this)();
    return ()=>{
      //DO componentWillUnmount
      componentWillUnmount.bind(this)();
    }
  }, []); //Empty

  // useEffect(() => {
  //   console.log(userSaveStore.url);
  //   console.log(userStore);
  //   if (!userStore.isAuthed) {
  //     //認証前、URL共有、前回画面あり
  //     return;
  //   }
  //   if (userSaveStore.url) {
  //     router.push(userSaveStore.url);
  //   }
  //   else {
  //     router.push('/mainpage');
  //   }
  // }, [userStore.permissions, userStore.isAuthed]);

  //handler
  const handleShareDialogOK = (shareinfo: ShareInfo) => {
    //localstorageに保存
    storageUtil.setLimitedItem('shareInfo=' + props.share, JSON.stringify(shareinfo), 60);
    storageUtil.removeLimitedItem('share=' + props.share);
    const query = {
      share: shareinfo.uuid
    };

    //権限チェック
    if (!checkPermission(shareinfo.url, userStore.permissions)) {
      console.log("閲覧権限なし");
      router.push('/nopermissions');
      return;
    }
    //mainpageに遷移する
    router.push({
      pathname: shareinfo.url,
      query: query,
    });
  }
  const handleShareDialogCancel = () => {
    storageUtil.removeLimitedItem('share=' + props.share);
    if (userSaveStore.url) {
      router.push(userSaveStore.url);
    }
    else {
      //mainpageに遷移する
      router.push('/mainpage');
    }
  }

  const title = `-`;
  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <CheckAuth>
      </CheckAuth>

      {/* 検索結果共有ダイアログ */}
      {!shareID ? null :
        <SelectShareDialog
          share={shareID}
          open={openShareDialog}
          setOpen={setOpenShareDialog}
          callbackOK={handleShareDialogOK}
          callbackCancel={handleShareDialogCancel}
        ></SelectShareDialog>
      }

    </>
  );
};

//Export
export default Index;

