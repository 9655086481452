// 状態はReactのstateで管理したい！
let signal: [(token: string) => void, (err: any) => void] | undefined;
let ready: Promise<string | undefined> = Promise.resolve(void 0);
let blocked = false;

// APIリクエストをブロックする
export function block() {
  if (typeof window === "undefined") {
    // サーバ側でレンダリング中に呼び出してしまった
    throw new Error("precondition failed.");
  }
  if (blocked) {
    return;
  }
  ready = new Promise<string>((resolve, reject) => signal = [resolve, reject]);
  blocked = true;
}

// APIリクエストのブロックを解除する
export function authorized(token: string) {
  if (!signal) {
    throw new Error("precondition failed.");
  }

  const [resolve] = signal;
  resolve(token);
  blocked = false;
}

// APIリクエストのブロックを解除する
export function authorizationFailure(err: Error) {
  if (!signal) {
    throw new Error("precondition failed.");
  }

  const [_, reject] = signal;
  reject(err);
  blocked = false;
}

function assignTokenIfNeeded(headers: Record<string, string>, maybeToken: string | undefined): Record<string, string> {
  if (!maybeToken) {
    return headers;
  }
  return Object.assign(headers, {
    "Authorization": `Bearer ${maybeToken}`,
  });
}

export const post = (suburl: string, params, userStore) => {
  //ユーザー情報を付加
  if(userStore) {
    //params = {...params, ...{userid:userStore.user_id, username: userStore.name}};
  }

  let url = process.env.NEXT_PUBLIC_API_ENDPOINT;
  url = url + suburl;
  const method = "POST";
  const body = JSON.stringify(params);
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };
  // const response = fetch(url)
  return ready.then((maybeToken) => fetch(url, {method, headers: assignTokenIfNeeded(headers, maybeToken), body, mode: "cors", credentials: "include" }));
}

/**
 * 匿名アクセスを許容するポスト
 */
export const postAnon = (suburl: string, params: any, userStore?: null, headers: Record<string, string> = {}) => {
  //ユーザー情報を付加
  if(userStore) {
    //params = {...params, ...{userid:userStore.user_id, username: userStore.name}};
  }

  let url = process.env.NEXT_PUBLIC_API_ENDPOINT;
  url = url + suburl;
  const method = "POST";
  const body = JSON.stringify(params);
  Object.assign(headers, {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  });
  // const response = fetch(url)
  return fetch(url, {method, headers, body, mode: "cors", credentials: "include" });
}

