const Pages = [
  { name: ``, url: '/', permissions: [], },
  { name: `メインページ`, url: '/mainpage', permissions: [], },
];

export const checkPermission = (url: string, permissions: string[]) => {
  const checkUrl = url
    .replace(/\?.*$/, "") // ?以降を削る
  //パスの位置+パスの長さ＝URLの長さ
  const page = Pages.find(page => checkUrl.lastIndexOf(page.url) + page.url.length == checkUrl.length);
  // console.log("checkPermission", url, checkUrl, permissions, page);
  if (!page) {
    return false;
  }
  return page.permissions.every(permission => permissions.includes(permission));
}
