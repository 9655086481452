import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";

import { userSlice } from "@/store/user";
import { RootState } from "@/store";

import { parseCookies, setCookie } from 'nookies';
import * as fetchUtil from "@/util/fetchUtil";
import { checkPermission } from "@/util/permissonUtil";
//import { Buffer } from 'buffer'; //'Buffer' is declared but its value never read.

import moment from 'moment';

const TOKEN_COOKIE_NAME = "cfx_token";

//Page Props
type Props = {
  error ?: {message: string},
  children?: JSX.Element[],
}

// 認証状態
type AuthState =
  | null // 何もしていない状態
  | "begin" // 認証開始
  | "authorized" // 認証済み
  | "unauthorized"; // 未認証

const AUTHORIZER_API = process.env['NEXT_PUBLIC_AUTHORIZER_API'];
const SSO_ENDPOINT = process.env['NEXT_PUBLIC_SSO_ENDPOINT'];
const API_ENDPOINT = process.env["NEXT_PUBLIC_API_ENDPOINT"];

/**
 * 認証状態フック
 */
function useAuthState(): AuthState {
  const router = useRouter();
  const [state, setState] = useState<AuthState>(null); // TODO 広域に保持するほうが適切 →CheckAuthが複数個所で使われていると成り立たないため
  // reduxに認証情報が格納されている模様
  const dispatch = useDispatch();
  const userStore = useSelector((state: RootState) => state.user);

  useEffect(() => {
    const now = moment();
    const start = moment().startOf('day').add(process.env.NEXT_PUBLIC_DB_START, 'hour');
    const end = moment().startOf('day').add(process.env.NEXT_PUBLIC_DB_END, 'hour');
    if (!now.isBetween(start, end)) {
      console.log("サービス時間外");
      router.push('/outsideservicehours');
      return null;
    }
    if (!router.isReady || state !== null) {
      // next/routerがreadyではない、stateが進んでいる
      return null;
    }

    setState("begin");
    fetchUtil.block(); // APIリクエストをブロック
    const code = router.query['code'];
    if (code) {
      // ?code=xxxを取り除く
      const params = new URLSearchParams();
      for (const [key ,val] of Object.entries(router.query)) {
        if (key === "code") {
          continue;
        }
        for (const v of Array.isArray(val) ? val : [val]) {
          params.append(key, v);
        }
      }
      const query = params.toString();
      router.replace(`${router.pathname}${query ? "?" + query : ""}`, void 0, { shallow: true });
    }

    (async () => {
      try {
        // ?codeまたはアクセストークン（Cookieに設定）で認証
        const url = new URL(AUTHORIZER_API, API_ENDPOINT); // API_ENDPOINTは使わずに捨てる
        if (typeof code === "string") {
          url.searchParams.set("code", code); // Authorizerで本文を見ることはできないので、クエリに設定 (ヘッダの操作はfetchUtilで隠されている)
        }

        const headers = {}
        const cookies = parseCookies(null);
        if (cookies[TOKEN_COOKIE_NAME]) {
          headers["Authorization"] = `Bearer ${cookies[TOKEN_COOKIE_NAME]}`;
        }

        let response: Response;
        try {
          response = await fetchUtil.postAnon(`${url.pathname}${url.search}`, {}, null, headers);
        } catch (e) {
          // CORSエラーの場合 →Authorizerが拒否した場合は、HTTP 403で返却されCORSヘッダが無くなっている
          dispatch(userSlice.actions.error({message: '認証できませんでした1'}));
          setState("unauthorized");
          fetchUtil.authorizationFailure(new Error("認証できませんでした1")); // APIリクエストのブロックを解除(常に失敗する)
          return;
        }
        if (response.status === 403) {
          dispatch(userSlice.actions.error({message: '認証できませんでした2'}));
          setState("unauthorized");
          fetchUtil.authorizationFailure(new Error("認証できませんでした2")); // APIリクエストのブロックを解除(常に失敗する)
          return;
        }
        if (!response.ok) {
          // 正常にレスポンスが返せない場合
          throw new Error("failed to authenticate."); // TODO error behavior
        }
        const result = await response.json();
        const { user, permissions, token } = result; // TODO レスポンスの型チェック
        dispatch(userSlice.actions.success());
        dispatch(userSlice.actions.setUserState({...user, permissions: permissions && Array.isArray(permissions) ? permissions : []}));

        if(token) {
          //他システム遷移用に必要
          //Cookie
          setCookie(null, TOKEN_COOKIE_NAME, token, {
            maxAge: parseInt(process.env.NEXT_PUBLIC_AUTHTOKEN_ALIVE_MIN) * 60, //60分
            domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
            path: '/',
          });
        }

        //権限チェック
        if (!checkPermission(location.href, permissions)) {
          console.log("閲覧権限なし");
          router.push('/nopermissions');
          return;
        }

        // フックの状態を認証済みに変更
        setState("authorized");
        // 認証が必要なpostを先へ進めるようにする
        fetchUtil.authorized(token);

      } catch (e) {
        dispatch(userSlice.actions.error(e));
        fetchUtil.authorizationFailure(e);
        throw e;
      }
    })();
  }, [router, state, setState, dispatch, userStore]);

  return state;
}

// 認証OKで子コンポーネントを描画するコンポーネント
function CheckAuth({ children }: Props) {
  const state = useAuthState();

  switch (state) {
  case null:
    // URL(`?code=xxx`がある状態)が確定しないタイミングでリクエストを投げると
    // ブラウザがCookieを付与しないケースがあるため、
    // このタイミングでは子コンポーネントを描画(子コンポーネントからのリクエスト発行を抑制するため)しない
    return <></>;

  case "begin":
    // 読み込み中のくるくるを出さずに画面を描画する。
    // 後続のAPIアクセスが未認証でこけないように、認証が解決するまで先へ進めないようにしている。
    return <>{children}</>;

  case "unauthorized":
    // 認証が必要なため、画面遷移
    window.location.href = SSO_ENDPOINT;
    return <>{}</>;

  case "authorized":
    return <>{children}</>;
  }
}

//Export
export default CheckAuth;


